import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom"
import uuid from 'uuid/v4';

import { actions, selectors, webSocketMessages as messages } from 'darwin_core';
import {
  AUTO_MODE,
  MANUAL_MODE,
  OFF_MODE,
} from 'darwin_core/utils/consts';
import '../App.css';

import Button from '../components/common/Button';
import Modal from '../components/common/Modal';
import ErrorDisplay from '../components/ErrorDisplay';
import DeviceSelector from '../components/common/DeviceSelector';

const setMode = ({ sendWsMessage, setMode: setModeAction }, deviceId, mode) => {
  const requestId = uuid();
  setModeAction(deviceId, requestId, mode);
  sendWsMessage(messages.setMode(deviceId, requestId, mode));
};

export const HomeComponent = (props) => {
  const { 
    email, logout, prt, temperature, selectedDevice, previousDevice, nextDevice, selectADevice,
    removeModeError, haveModeErrors, modeError,
  } = props;

  return (
  <div>
    <div>Ideal</div>
    <div>Darwin</div>

    <div>{email}</div>
    <Button onPress={() => { logout() }}>
      Logout
    </Button>
    <div>
      <Link to="/gateways/">List All Smart Devices</Link>
    </div>

    <DeviceSelector
      nextDevice={() => selectADevice(nextDevice)}
      previousDevice={() => selectADevice(previousDevice)}
      selectedDevice={selectedDevice}
    />

    <div>
      <Button onPress={() => setMode(props, selectedDevice, AUTO_MODE)}>
        Auto
      </Button>

      <Button onPress={() => setMode(props, selectedDevice, MANUAL_MODE)}>
        Manual
      </Button>

      <Button onPress={() => setMode(props, selectedDevice, OFF_MODE)}>
        Off
      </Button>

    <div>{temperature || "??"}</div>
    <div>{JSON.stringify(prt)}</div>

    <Modal visible={haveModeErrors}>
      <ErrorDisplay error={modeError} onClose={() => removeModeError(modeError)} />
    </Modal>

  </div>
  </div>
)};

const mapStateToProps = state => {
  const selectedDevice = selectors.getSelectedDevice(state);

  return {
    selectedDevice,
    nextDevice: selectors.nextDevice(state),
    previousDevice: selectors.previousDevice(state),
    devices: selectors.getDeviceList(state),
    email: selectors.getCurrentEmail(state, selectedDevice),
    prt: selectors.getCurrentMode(state, selectedDevice),
    modeError: selectors.getFirstModeError(state),
    haveModeErrors: selectors.haveModeErrors(state),
    temperature: selectors.getCurrentTemperature(state, selectedDevice),
  }
};

export default connect(mapStateToProps, { ...actions })(HomeComponent);
