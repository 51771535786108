export var LOGOUT_STARTED = 'Logout';
export var LOGOUT_COMPLETED = 'Logout Completed';
export var LOGOUT_FAILED = 'Logout Failed';
export var AMPLIFY_STATE_CHANGE = 'Amplify State Change';
export var SIGNUP = 'Sign up form submitted';
export var SIGNUP_SENSITIVE = 'Sign up form sensitive info';
export var SIGNUP_STARTED = 'Signup Started';
export var SIGNUP_COMPLETE = 'Signup Completed';
export var SIGNUP_FAILED = 'Signup Failed';
export var ACCEPT_TERMS_SET = 'Accept Terms & Conditions set';
export var ACCEPT_TERMS_CLEARED = 'Accept Terms & Conditions cleared';
export var ACCEPT_MARKETING_SET = 'Accept Marketing set';
export var DARWIN_REGISTRATION_REQUIRED = 'Darwin registration required';
export var DARWIN_REGISTRATION_STARTED = 'Darwin registration started';
export var DARWIN_REGISTRATION_FAILED = 'Darwin registration failed';
export var DARWIN_AUTHENTICATED = 'Darwin authenticated';
export var DARWIN_AUTHENTICATION_FAILED = 'Darwin authentication failed';
export var DEVICE_REGISTRATION_SERIALNUMBER_SET = 'Device registration serial number added';
export var DEVICE_REGISTRATION_PIN_SET = 'Device registration PIN added';
export var DEVICE_REGISTRATION_ATTEMPTS_RESET = 'Device registration failed attempts reset';
export var DEVICE_REGISTRATION_STARTED = 'Device Registration Started';
export var DEVICE_REGISTRATION_SUCCEEDED = 'Device Registration Succeeded';
export var DEVICE_REGISTRATION_FAILED = 'Device Registration Failed';
export var DEVICE_REGISTRATION_COMPLETED = 'Device Registration Completed';
export var DEVICE_REGISTRATION_ERROR_SET = 'Device Registration Error Set';
export var LIST_ALL_DEVICES_STARTED = 'List All Devices Started';
export var LIST_ALL_DEVICES_FAILED = 'List All Devices Failed';
export var LIST_ALL_DEVICES_SUCCEEDED = 'List All Devices Complete';
export var DEVICE_SELECTED = 'Select a main device to get MQTT messages from';
export var GET_IOT_TOKEN_STARTED = 'Get IoT Token Started';
export var GET_IOT_TOKEN_SUCCEEDED = 'Get IoT Token Succeeded';
export var GET_IOT_TOKEN_FAILED = 'Get IoT Token Failed';
export var WEBSOCKET_DEVICE_LIST_RECEIVED = 'Received Device List Message';
export var WEBSOCKET_SET_PRT_MODE_OFF_RECEIVED = 'Received Set PRT Mode Off Message';
export var WEBSOCKET_SET_PRT_MODE_OFF_REJECTED = 'Rejected Set PRT Mode Off Message';
export var WEBSOCKET_SET_PRT_MODE_AUTO_RECEIVED = 'Received Set PRT Mode Auto Message';
export var WEBSOCKET_SET_PRT_MODE_AUTO_REJECTED = 'Rejected Set PRT Mode Auto Message';
export var WEBSOCKET_SET_PRT_MODE_MANUAL_RECEIVED = 'Received Set PRT Mode Manual Message';
export var WEBSOCKET_SET_PRT_MODE_MANUAL_REJECTED = 'Rejected Set PRT Mode Manual Message';
export var WEBSOCKET_PRT_LIVE_RECEIVED = 'Received PRT Live Message';
export var WEBSOCKET_MESSAGE_RECEIVED = 'Received websocket message';
export var WEBSOCKET_OPENED = 'Websocket Opened';
export var WEBSOCKET_CLOSED = 'Websocket Closed';
export var SET_MODE_STARTED = 'Set Mode Started';
export var SET_MODE_TIMED_OUT = 'Set Mode Timed Out';
export var ADD_MODE_ERROR = 'Add Mode Error';
export var REMOVE_MODE_ERROR = 'Remove Mode Error';