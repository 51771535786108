import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom"
import { actions, selectors } from 'darwin_core';
import '../App.css';

export const MQTTItem = ({ gateway }) => {
  return (
    <div>
      <Link to={{
        pathname: '/mqtt',
        state: {
          gateway: gateway
        }
      }}>
        MQTT
      </Link>
    </div>
  );
}

const Gateway = (props) => {
  const gateway = props.location.state.gateway;
  if (!gateway) {
    return null;
  }

  return (
    <>
      <div>
        <p>
          Serial Number:
        {' '}
          {gateway.SerialNumber}
          {' '}
        </p>
        <p>
          GatewayId:
        {' '}
          {gateway.GatewayId}
        </p>
      </div>
      <MQTTItem gateway={gateway} />
    </>
  );
};

const mapStateToProps = state => ({
  gatewayList: selectors.getGatewayList(state),
});

export default connect(mapStateToProps, { ListAllDevices: actions.ListAllDevices })(Gateway);
