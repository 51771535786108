import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Link } from "react-router-dom"
import { Auth } from 'aws-amplify';
import { actions, selectors } from 'darwin_core';
import '../App.css';

export const GatewayItem = ({ gateway, onPressItem }) => {
  return (
    <div>
      <Link to={{
        pathname: '/gateway',
        state: {
          gateway: gateway
        }
      }}>
        {gateway.SerialNumber}
      </Link>
    </div>
  );
}

export const GatewayList = props => {
  const { gateways } = props;
  const listItems = gateways.map((gateway) => 
    <GatewayItem key={gateway.attributes.SerialNumber} gateway={gateway.attributes} />
  );
  return (
    <div>
      <h1>Gateways</h1>
      {listItems}
    </div>
  );
}

export const GatewaysComponent = ({gateways}) => (
  <div>
    <div>
      <h1>List All Smart Devices</h1>
      <GatewayList gateways={gateways}/>
    </div>
  </div>
);

export class DisplayGatewayList extends React.PureComponent {
  componentDidMount() {
    this.props.ListAllDevices(Auth);
  }

  render(){
    const { gatewayList, callStatus } = this.props;

    if (callStatus.inProgress) {
      return (
        <div>
          <h3>Loading</h3>
        </div>
      );
    }

    if (callStatus.failed) {
      return (
        <div>
          <h3>Failed</h3>
        </div>
      );
    }

    if (!gatewayList.data) {
      return null;
    }

    return (<GatewaysComponent gateways={gatewayList.data}/>);
  }

}

const mapStateToProps = state => ({
  gatewayList: selectors.getGatewayList(state),
  callStatus: selectors.getGatewayListCallStatus(state),
});

export default connect(mapStateToProps, { ListAllDevices: actions.ListAllDevices })(DisplayGatewayList);
