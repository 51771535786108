import _objectSpread from "/Users/aidan.treasure/dev/ideal/darwin_app/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
// @flow
import * as actionTypes from './action-types';
/*
type State = {
  +userToken: string,
  // ...
};
*/

export var initialState = {
  isAuthenticated: false,
  email: '',
  password: '',
  termsAndConditionsCompleted: false,
  AcceptedMarketing: false,
  AcceptedTerms: false,
  TermsAgreedAt: '',
  TermsVersion: ''
};
export var auth = function auth() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    // case actionTypes.RESET_AUTH_STATE:
    //   return initialState;
    case actionTypes.LOGOUT_COMPLETED:
      return _objectSpread({}, initialState, {
        email: state.email
      });

    case actionTypes.AMPLIFY_STATE_CHANGE:
      return _objectSpread({}, state, {
        isAuthenticated: action.isAuthenticated,
        email: action.email
      });

    case actionTypes.SIGNUP:
      return _objectSpread({}, state, {
        email: action.email,
        termsAndConditionsCompleted: true,
        AcceptedMarketing: action.AcceptedMarketing,
        TermsAgreedAt: action.TermsAgreedAt,
        TermsVersion: action.TermsVersion
      });

    case actionTypes.ACCEPT_TERMS_SET:
      return _objectSpread({}, state, {
        AcceptedTerms: action.acceptTerms,
        TermsAgreedAt: action.acceptedTermsTime,
        TermsVersion: action.termsVersion
      });

    case actionTypes.ACCEPT_TERMS_CLEARED:
      return _objectSpread({}, state, {
        AcceptedTerms: false,
        TermsAgreedAt: '',
        TermsVersion: ''
      });

    case actionTypes.ACCEPT_MARKETING_SET:
      return _objectSpread({}, state, {
        AcceptedMarketing: action.acceptMarketing
      });

    default:
      return state;
  }
};
export var authSensitive = function authSensitive() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionTypes.SIGNUP_SENSITIVE:
      return _objectSpread({}, state, {
        password: action.password
      });

    default:
      return state;
  }
};
export var darwinAuthInitialState = {
  isAuthenticated: false,
  authenticationFailed: false,
  registrationRequired: false,
  registrationFailed: false,
  registrationInProgress: false
};
export var darwinAuth = function darwinAuth() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : darwinAuthInitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionTypes.LOGOUT_COMPLETED:
      return darwinAuthInitialState;

    case actionTypes.DARWIN_REGISTRATION_REQUIRED:
      return _objectSpread({}, state, {
        registrationRequired: true
      });

    case actionTypes.DARWIN_REGISTRATION_STARTED:
      return _objectSpread({}, state, {
        isRegistering: true
      });

    case actionTypes.DARWIN_REGISTRATION_FAILED:
      return _objectSpread({}, state, {
        registrationFailed: true
      });

    case actionTypes.DARWIN_AUTHENTICATION_FAILED:
      return _objectSpread({}, state, {
        authenticationFailed: true
      });

    case actionTypes.DARWIN_AUTHENTICATED:
      return _objectSpread({}, state, {
        registrationFailed: false,
        authenticationFailed: false,
        isRegistering: false,
        isAuthenticated: true,
        registrationRequired: false
      });

    default:
      return state;
  }
};
export var deviceRegistrationInitialState = {
  registrationAttempts: 0,
  serialNumber: '',
  pin: '',
  deviceRegistrationSucceeded: false,
  deviceRegistrationInProgress: false,
  deviceRegistrationError: '',
  errorTitle: '',
  deviceRegistrationInputValid: false
};

var validateSerialAndPin = function validateSerialAndPin(serialNumber, pin) {
  var regexSerial = /^(\d{14})$/g;
  var regexPin = /^(\d{4})$/g;

  if (regexSerial.test(serialNumber) && regexPin.test(pin)) {
    return true;
  }

  return false;
};

export var deviceRegistration = function deviceRegistration() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : deviceRegistrationInitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionTypes.DEVICE_REGISTRATION_ATTEMPTS_RESET:
      return _objectSpread({}, state, {
        registrationAttempts: deviceRegistrationInitialState.registrationAttempts
      });

    case actionTypes.DEVICE_REGISTRATION_SERIALNUMBER_SET:
      return _objectSpread({}, state, {
        serialNumber: action.serialNumber,
        deviceRegistrationInputValid: validateSerialAndPin(action.serialNumber, state.pin)
      });

    case actionTypes.DEVICE_REGISTRATION_PIN_SET:
      return _objectSpread({}, state, {
        pin: action.pin,
        deviceRegistrationInputValid: validateSerialAndPin(state.serialNumber, action.pin)
      });

    case actionTypes.DEVICE_REGISTRATION_STARTED:
      return _objectSpread({}, state, {
        deviceRegistrationInProgress: true
      });

    case actionTypes.DEVICE_REGISTRATION_ERROR_SET:
      return _objectSpread({}, state, {
        deviceRegistrationError: action.deviceRegistrationError
      });

    case actionTypes.DEVICE_REGISTRATION_FAILED:
      return _objectSpread({}, state, {
        deviceRegistrationInProgress: false,
        errorTitle: action.errorTitle,
        registrationAttempts: state.registrationAttempts + 1
      });

    case actionTypes.DEVICE_REGISTRATION_SUCCEEDED:
      return _objectSpread({}, state, {
        deviceRegistrationSucceeded: true
      });

    case actionTypes.DEVICE_REGISTRATION_COMPLETED:
      return deviceRegistrationInitialState;

    case actionTypes.LOGOUT_COMPLETED:
      return deviceRegistrationInitialState;

    default:
      return state;
  }
};
export var gatewayManagementInitialState = {
  gateways: [],
  listAllDevicesInProgress: false,
  listAllDevicesSucceeded: false,
  listAllDevicesErrorMessage: '',
  gatewayToken: {},
  gatewayGetTokenInProgress: false,
  gatewayGetTokenSucceeded: false,
  gatewayGetTokenErrorMessage: ''
};
export var gatewayManagement = function gatewayManagement() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : gatewayManagementInitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionTypes.LIST_ALL_DEVICES_STARTED:
      return _objectSpread({}, state, {
        listAllDevicesInProgress: true,
        gateways: []
      });

    case actionTypes.LIST_ALL_DEVICES_FAILED:
      return _objectSpread({}, state, {
        listAllDevicesSucceeded: false,
        listAllDevicesInProgress: false,
        listAllDevicesErrorMessage: action.message
      });

    case actionTypes.LIST_ALL_DEVICES_SUCCEEDED:
      return _objectSpread({}, state, {
        listAllDevicesSucceeded: true,
        listAllDevicesInProgress: false,
        gateways: action.gateways
      });

    case actionTypes.GET_IOT_TOKEN_STARTED:
      return _objectSpread({}, state, {
        gatewayGetTokenInProgress: true,
        gatewayGetTokenSucceeded: false,
        gatewayGetTokenErrorMessage: '',
        gatewayToken: {}
      });

    case actionTypes.GET_IOT_TOKEN_SUCCEEDED:
      return _objectSpread({}, state, {
        gatewayToken: action.gatewayToken,
        gatewayGetTokenInProgress: false,
        gatewayGetTokenSucceeded: true
      });

    case actionTypes.GET_IOT_TOKEN_FAILED:
      return _objectSpread({}, state, {
        gatewayGetTokenInProgress: false,
        gatewayGetTokenSucceeded: false,
        gatewayGetTokenErrorMessage: action.message,
        gatewayToken: {}
      });

    default:
      return state;
  }
}; // combined Root reducer now lives in store