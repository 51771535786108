export var getLastEmail = function getLastEmail(state) {
  return state.auth.email;
};
export var getPassword = function getPassword(state) {
  return state.authSensitive.password;
};
export var isAuthenticated = function isAuthenticated(state) {
  return state.auth.isAuthenticated === true;
};
export var isDarwinAuthenticated = function isDarwinAuthenticated(state) {
  return state.darwinAuth.isAuthenticated === true;
};
export var getDarwinRegistering = function getDarwinRegistering(state) {
  return state.darwinAuth.isRegistering;
};
export var getDarwinRegistrationRequired = function getDarwinRegistrationRequired(state) {
  return state.darwinAuth.registrationRequired;
};
export var getDarwinAuthenticationFailed = function getDarwinAuthenticationFailed(state) {
  return state.darwinAuth.authenticationFailed;
};
export var getDarwinRegistrationFailed = function getDarwinRegistrationFailed(state) {
  return state.darwinAuth.registrationFailed;
};
export var getAcceptedMarketing = function getAcceptedMarketing(state) {
  return state.auth.AcceptedMarketing;
};
export var getAcceptedTerms = function getAcceptedTerms(state) {
  return state.auth.AcceptedTerms;
};
export var getTermsAgreedAt = function getTermsAgreedAt(state) {
  return state.auth.TermsAgreedAt;
};
export var getTermsVersion = function getTermsVersion(state) {
  return state.auth.TermsVersion;
};
export var getTermsAndConditionsCompleted = function getTermsAndConditionsCompleted(state) {
  return state.auth.termsAndConditionsCompleted;
};
export var getCurrentEmail = function getCurrentEmail(state) {
  if (isAuthenticated(state)) {
    return state.auth.email;
  }

  return '';
};
export var getDeviceRegistrationAttempts = function getDeviceRegistrationAttempts(state) {
  return state.deviceRegistration.registrationAttempts;
};
export var getDeviceRegistrationSerialNumber = function getDeviceRegistrationSerialNumber(state) {
  return state.deviceRegistration.serialNumber;
};
export var getDeviceRegistrationPin = function getDeviceRegistrationPin(state) {
  return state.deviceRegistration.pin;
};
export var getDeviceRegistrationError = function getDeviceRegistrationError(state) {
  return state.deviceRegistration.deviceRegistrationError;
};
export var getDeviceRegistrationErrorTitle = function getDeviceRegistrationErrorTitle(state) {
  return state.deviceRegistration.errorTitle;
};
export var getDeviceRegistrationSuccess = function getDeviceRegistrationSuccess(state) {
  return state.deviceRegistration.deviceRegistrationSucceeded;
};
export var getDeviceRegistrationInProgress = function getDeviceRegistrationInProgress(state) {
  return state.deviceRegistration.deviceRegistrationInProgress;
};
export var getGatewayList = function getGatewayList(state) {
  return state.gatewayManagement.gateways;
};
export var getDevicecRegistrationSubmitable = function getDevicecRegistrationSubmitable(state) {
  return state.deviceRegistration.deviceRegistrationInputValid && !state.deviceRegistration.deviceRegistrationInProgress;
};
export var getGatewayListCallStatus = function getGatewayListCallStatus(state) {
  return {
    inProgress: state.gatewayManagement.listAllDevicesInProgress,
    succeeded: state.gatewayManagement.listAllDevicesSucceeded,
    failed: (state.gatewayManagement.listAllDevicesInProgress || state.gatewayManagement.listAllDevicesSucceeded) === false,
    errorMessage: state.gatewayManagement.listAllDevicesErrorMessage
  };
};
export var getGatewayToken = function getGatewayToken(state) {
  return state.gatewayManagement.gatewayToken;
};
export var getGatewayTokenCallStatus = function getGatewayTokenCallStatus(state) {
  return {
    inProgress: state.gatewayManagement.gatewayGetTokenInProgress,
    succeeded: state.gatewayManagement.gatewayGetTokenSucceeded,
    failed: (state.gatewayManagement.gatewayGetTokenInProgress || state.gatewayManagement.gatewayGetTokenSucceeded) === false,
    errorMessage: state.gatewayManagement.gatewayGetTokenErrorMessage
  };
};
export var getDesiredModeForDevice = function getDesiredModeForDevice(state, deviceId) {
  var value = state.pendingPrt.devices[deviceId];
  return value ? value.mode : null;
};
export var getActualModeForDevice = function getActualModeForDevice(state, deviceId) {
  var value = state.prt.find(function (x) {
    return x.deviceId === deviceId;
  });
  return value ? value.mode : null;
};
export var getCurrentMode = function getCurrentMode(state, deviceId) {
  return {
    mode: getDesiredModeForDevice(state, deviceId) || getActualModeForDevice(state, deviceId),
    actualMode: getActualModeForDevice(state, deviceId)
  };
};
export var getCurrentTemperature = function getCurrentTemperature(state, deviceId) {
  var device = state.prt.find(function (x) {
    return x.deviceId === deviceId;
  });
  return device ? device.temperature / 100 : null;
};
export var getSelectedDevice = function getSelectedDevice(state) {
  return state.devices.selectedDevice;
};
export var getDeviceList = function getDeviceList(state) {
  return {
    devices: state.devices.devices
  };
};
export var previousDevice = function previousDevice(state) {
  var _state$devices = state.devices,
      devices = _state$devices.devices,
      selectedDevice = _state$devices.selectedDevice;
  var indexOfSelected = devices.findIndex(function (x) {
    return x.serialNumber === selectedDevice;
  });

  if (indexOfSelected === -1) {
    return 'No devices';
  }

  if (indexOfSelected === 0) {
    indexOfSelected = devices.length;
  }

  indexOfSelected -= 1;
  var previousDeviceSNInArray = devices[indexOfSelected].serialNumber;
  return previousDeviceSNInArray;
};
export var nextDevice = function nextDevice(state) {
  var _state$devices2 = state.devices,
      devices = _state$devices2.devices,
      selectedDevice = _state$devices2.selectedDevice;
  var indexOfSelected = devices.findIndex(function (x) {
    return x.serialNumber === selectedDevice;
  });

  if (indexOfSelected === -1) {
    return 'No devices';
  }

  indexOfSelected += 1;
  indexOfSelected %= devices.length;
  var nextDeviceSNInArray = devices[indexOfSelected].serialNumber;
  return nextDeviceSNInArray;
};
export var getFirstModeError = function getFirstModeError(state) {
  return state.pendingPrt.errors.length > 0 ? state.pendingPrt.errors[0] : {};
};
export var haveModeErrors = function haveModeErrors(state) {
  return state.pendingPrt.errors && state.pendingPrt.errors.length > 0;
};