// these are not action creators
// they are helpers for creating Halo messages for sending via websockets
export var hello = function hello() {
  return {
    action: 'hello'
  };
};
export var setMode = function setMode(deviceId, requestId, mode) {
  return {
    action: 'setPrtMode',
    data: {
      requestId: requestId,
      deviceId: deviceId,
      mode: mode
    }
  };
};