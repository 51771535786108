export default {
  "region": "eu-west-2",
  "darwin_api_endpoint": "https://flh0n1jxmh.execute-api.eu-west-2.amazonaws.com/dev",
  "darwin_iot_endpoint": "a1f7e9wxws15k4-ats.iot.eu-west-2.amazonaws.com",
  "darwin_user_pool_arn": "arn:aws:cognito-idp:eu-west-2:176647328773:userpool/eu-west-2_fQRx9eJP3",
  "darwin_user_pool_client_id": "lgjtkt0clasu9pkj1gcq7ao80",
  "darwin_user_pool_endpoint": "cognito-idp.eu-west-2.amazonaws.com/eu-west-2_fQRx9eJP3",
  "darwin_user_pool_id": "eu-west-2_fQRx9eJP3",
  "darwin_websocket_api_endpoint": "wss://q0m4cjrtvb.execute-api.eu-west-2.amazonaws.com/dev" // 2019-4-12 20:27:24

};