import React from 'react';
import Button from './Button';

export default function DeviceSelector({ nextDevice, previousDevice, selectedDevice }) {
  return (
    <div>
      <Button onPress={() => previousDevice()}>
        Previous device
      </Button>

      <Button onPress={() => nextDevice()}>
        Next device
      </Button>
      <p>{JSON.stringify(selectedDevice)}</p>
    </div>
  );
}
