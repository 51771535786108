import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { actions, selectors } from 'darwin_core';
import '../App.css';
import AWSIoTData from 'aws-iot-device-sdk';
import Button from '../components/common/Button';

import config from '../.env';

const Status = ({ mqttStatus }) => (
  <div>
    Status:
    {' '}
    {mqttStatus}
  </div>
);

const Publish = ({
  topic, message, onChangeTopic, onChangeMessage, onSend,
}) => (
  <form onSubmit={(event) => {
    onSend();
    event.preventDefault();
  }}>
    <p>Publish</p>

    <p>Topic</p>
    <input
      value={topic}
      onChange={(event) => onChangeTopic(event.target.value)}
    />
    <p>Message</p>
    <input
      value={message}
      onChange={(event) => onChangeMessage(event.target.value)}
    />
    <input type="submit" value="Send" />
  </form>
);

const Received = ({
  messages,
}) => {
  const receivedMessages = messages.map((message) => 
    <li key={message.id}>
      <b>{message.topic}</b> <p>{message.message}</p>
    </li>
  );

  return (
    <div>
      <h3>Received</h3>
      <ul>
        {receivedMessages}
      </ul>
    </div>
  );
};

class MqttDebugComponent extends Component {
  constructor(props) {
    super(props);
    const gateway = props.location.state.gateway;
    this.gatewayId = gateway.SerialNumber;
    this.state = {
      publishTopic: `${this.gatewayId}/test`,
      publishMessage: '',
      mqttStatus: 'Connecting',
      receivedMessages: [],
    };

    this.createMqttConnection = this.createMqttConnection.bind(this);
  }

  componentDidMount() {
    this.props.requestToken(Auth, this.gatewayId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.callStatus.succeeded === true
      && prevProps.callStatus.succeeded === false) {
      this.createMqttConnection(this.props.gatewayToken);
    }
  }

  componentWillUnmount() {
    // close the connection (gracefully)
    if (this.client) {
      this.client.end();
    }
  }

  createMqttConnection(credentials) {
    this.reconnectAttempts = 0;

    const client = 
       AWSIoTData.device({
       region: 'eu-west-2',
       host: config.darwin_iot_endpoint,
       protocol: 'wss',
       maximumReconnectTimeMs: 8000,
       debug: true,
       accessKeyId: credentials.accessKeyId,
       secretKey: credentials.secretAccessKey,
       sessionToken: credentials.sessionToken,
    });

    this.client = client;
    this.client.on('connect', () => {
      this.setState({ mqttStatus: 'Connected' });
      this.reconnectAttempts = 0;

      console.log('Connected');

      client.subscribe(`${this.gatewayId}/app`, (err) => {
        if (err) {
          console.log(err);
        }
      });
    });

    this.client.on('message', (topic, message) => {
      // message is Buffer
      console.log(message.toString());

      const messageObj = {
        id: this.state.receivedMessages.length, topic, message: message.toString(),
      };
      this.setState(prevState => ({
        receivedMessages: prevState.receivedMessages.concat(messageObj),
      }));
    });

    this.client.on('error', (error) => {
      this.setState({ mqttStatus: 'Error' });
      console.log(error);
    });

    this.client.on('reconnect', () => {
      this.setState({ mqttStatus: 'Attempting to reconnect' });
      this.reconnectAttempts = this.reconnectAttempts + 1;

      if (this.reconnectAttempts > 5) {
        this.client.end();
        this.setState({ mqttStatus: 'Disconnected' });
      }

      console.log('Reconnect');
    });

    this.client.on('offline', () => {
      this.setState({ mqttStatus: 'Offline' });
      console.log('offline');
    });
  }

  render() {
    const { callStatus } = this.props;

    if (callStatus.inProgress) {
      return (
        <div>
          <p>Loading</p>
        </div>
      );
    }
    if (callStatus.failed) {
      return (
        <div>
          <p>Failed</p>
        </div>
      );
    }

    return (
      <div>
        <Status
          mqttStatus={this.state.mqttStatus}
        />

        <Publish
          topic={this.state.publishTopic}
          message={this.state.publishMessage}
          onChangeTopic={text => this.setState({ publishTopic: text })}
          onChangeMessage={text => this.setState({ publishMessage: text })}
          onSend={() => this.client.publish(this.state.publishTopic, this.state.publishMessage)}
          mqttStatus={this.state.mqttStatus}
        />

        <Received
          messages={this.state.receivedMessages}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  gatewayToken: selectors.getGatewayToken(state),
  callStatus: selectors.getGatewayTokenCallStatus(state),
});

export default connect(mapStateToProps, {
  requestToken: actions.getIotToken,
})(MqttDebugComponent);
