/*
 * This component customises the AWS Amplify screens to avoid working directly with the API
 * There seem to be two aproachces to completing the Sign In/Up process.
 * We've chosen to listen into the onStateChange event push it to redux and switch out
 * the display. The other approach is to define a sub-component of Authenticator which comes to the
 * front when the state is SignedIn
 */

import React from 'react';
import Amplify, { Auth } from 'aws-amplify';

import {
  SignIn, ConfirmSignUp,
  ConfirmSignIn, VerifyContact, ForgotPassword, Authenticator,
} from 'aws-amplify-react';
import { connect } from 'react-redux';
import { actions, selectors } from 'darwin_core';

import config from '../.env';

Amplify.configure({
  Auth: {
    region: config.region,
    userPoolId: config.darwin_user_pool_id,
    userPoolWebClientId: config.darwin_user_pool_client_id,
  },
});

const onStateChange = (authState, authData, amplifyStateChange) => {
  if (authState === 'signedIn') {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        amplifyStateChange(authState, user.attributes.email)
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

const switcher = (props) => {
  const { amplifyStateChange, isAuthenticated, initialEmail } = props;
  if (!isAuthenticated) {
    return (
      <Authenticator
        {...props}
        hideDefault
        onStateChange={(authState, authData) => {
          onStateChange(authState, authData, amplifyStateChange);
        }}
      >
        <SignIn initialEmail={initialEmail} />
        <ConfirmSignIn />
        <VerifyContact />
        <ConfirmSignUp />
        <ForgotPassword />
      </Authenticator>);
  }

  return props.children;
};

const mapStateToProps = state => ({
  isAuthenticated: selectors.isAuthenticated(state),
  initialEmail: selectors.getLastEmail(state),
});

export default connect(
  mapStateToProps,
  { amplifyStateChange: actions.amplifyStateChange },
)(switcher);
