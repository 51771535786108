import { Component } from 'react';
import { Auth } from 'aws-amplify';

import config from '../.env';

export default class WebSocketHandler extends Component {

  constructor(props) {
    super(props);
    this.sendWsMessage = this.sendWsMessage.bind(this);
  }

  async componentDidMount() {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const server=`${config.darwin_websocket_api_endpoint}?token=${token}`;

    this.ws = new WebSocket(server);

    this.ws.onopen = () => {
      this.sendWsMessage({
        action: 'hello',
      });
      this.props.onOpen(); 
    };
    this.ws.onmessage = (e) => {
      console.log(e.data);
      const json = JSON.parse(e.data);
      this.props.onMessage(json);
    };
    this.ws.onclose = this.props.onClose;
  }

  componentWillUnmount() {
    if (this.ws) {
      this.ws.close();
    }
  }

  sendWsMessage(args) {
    if (this.ws) {
      console.log('Sending WS', args);
      const payload = JSON.stringify(args);
      this.ws.send(payload);
    }
  }

  render() {
    return this.props.children(this.sendWsMessage);
  }
}
