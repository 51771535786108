import _defineProperty from "/Users/aidan.treasure/dev/ideal/darwin_app/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/Users/aidan.treasure/dev/ideal/darwin_app/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "/Users/aidan.treasure/dev/ideal/darwin_app/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import * as actionTypes from './action-types'; // [
// { deviceId: "id", selected: true}
// { deviceId: "id2", selected: false}
// ]
// const insertArray = (array, device) => {
//   const newArray = array.slice();
//   newArray.splice(array.length, 0, device);
//   return newArray;
// };

export var devicesInitialState = {
  selectedDevice: null,
  devices: []
};
export var devices = function devices() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : devicesInitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionTypes.LOGOUT_COMPLETED:
      return devicesInitialState;

    case actionTypes.DEVICE_SELECTED:
      return _objectSpread({}, state, {
        selectedDevice: action.serialNumber
      });

    case actionTypes.WEBSOCKET_DEVICE_LIST_RECEIVED:
      {
        var selectedDeviceInDevices = state.devices.find(function (device) {
          return state.selectedDevice === device.serialNumber;
        });
        return _objectSpread({}, state, {
          selectedDevice: (selectedDeviceInDevices || action.data.devices[0]).serialNumber,
          devices: action.data.devices
        });
      }

    default:
      return state;
  }
};
export var prtInitialState = [];
export var prt = function prt() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : prtInitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionTypes.LOGOUT_COMPLETED:
      return prtInitialState;

    case actionTypes.WEBSOCKET_DEVICE_LIST_RECEIVED:
      return _toConsumableArray(action.data.devices.map(function (device) {
        return {
          deviceId: device.serialNumber,
          mode: device.temperatureMode,
          temperature: device.localTemperature
        };
      }));

    case actionTypes.WEBSOCKET_PRT_LIVE_RECEIVED:
      return [].concat(_toConsumableArray(state.filter(function (x) {
        return x.deviceId !== action.data.serialNumber;
      })), [{
        deviceId: action.data.serialNumber,
        mode: action.data.temperatureMode,
        temperature: action.data.localTemperature
      }]);

    default:
      break;
    //    case actionTypes.SET_MODE_STARTED:
    //      return {
    //        ...state,
    //        desiredMode: action.desiredMode,
    //        requestId: action.requestId,
    //      };
    //    case actionTypes.SET_MODE_TIMED_OUT:
    //      if (action.requestId === state.requestId) {
    //        return {
    //          ...state,
    //          desiredMode: state.actualMode,
    //          requestId: undefined,
    //        };
    //      }
    //      break;
  }

  return state;
};
export var pendingPrtInitialState = {
  devices: {},
  errors: []
}; // {
//   devices: {
//     [deviceId]: {
//       requestId: xxx,
//       mode: 'xxx',
//       setPoint: 23.5,
//     },
//   },
//   errors: [
//     {
//       deviceId: "xxx",
//       requestId: "xxx",
//       message: "xxxx",
//     },
//   }
// }

export var pendingPrt = function pendingPrt() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : pendingPrtInitialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionTypes.SET_MODE_STARTED:
      return _objectSpread({}, state, {
        devices: _objectSpread({}, state.devices, _defineProperty({}, action.deviceId, {
          requestId: action.requestId,
          mode: action.desiredMode,
          setPoint: action.setPoint
        }))
      });

    case actionTypes.SET_MODE_TIMED_OUT:
      {
        var device = state.devices[action.deviceId];

        if (device && device.requestId === action.requestId) {
          return _objectSpread({}, state, {
            devices: _objectSpread({}, state.devices, _defineProperty({}, action.deviceId, {})),
            errors: [].concat(_toConsumableArray(state.errors), [{
              requestId: action.requestId,
              deviceId: action.deviceId,
              message: 'Command timed out'
            }])
          });
        }

        break;
      }

    case actionTypes.WEBSOCKET_SET_PRT_MODE_OFF_REJECTED:
    case actionTypes.WEBSOCKET_SET_PRT_MODE_AUTO_REJECTED:
    case actionTypes.WEBSOCKET_SET_PRT_MODE_MANUAL_REJECTED:
      {
        var _action$data = action.data,
            deviceId = _action$data.deviceId,
            requestId = _action$data.requestId;
        var _device = state.devices[deviceId];

        if (_device && _device.requestId === requestId) {
          return _objectSpread({}, state, {
            devices: _objectSpread({}, state.devices, _defineProperty({}, deviceId, {})),
            errors: [].concat(_toConsumableArray(state.errors), [{
              requestId: requestId,
              deviceId: deviceId,
              message: 'Command rejected'
            }])
          });
        }

        break;
      }

    case actionTypes.WEBSOCKET_SET_PRT_MODE_OFF_RECEIVED:
    case actionTypes.WEBSOCKET_SET_PRT_MODE_AUTO_RECEIVED:
    case actionTypes.WEBSOCKET_SET_PRT_MODE_MANUAL_RECEIVED:
      {
        var _action$data2 = action.data,
            _deviceId = _action$data2.deviceId,
            _requestId = _action$data2.requestId;
        var _device2 = state.devices[_deviceId];

        if (_device2 && _device2.requestId === _requestId) {
          return _objectSpread({}, state, {
            devices: _objectSpread({}, state.devices, _defineProperty({}, _deviceId, _objectSpread({}, _device2, {
              requestId: undefined
            })))
          });
        }

        break;
      }

    case actionTypes.REMOVE_MODE_ERROR:
      console.log(state, action);
      return _objectSpread({}, state, {
        errors: _toConsumableArray(state.errors.filter(function (x) {
          return x.requestId !== action.requestId;
        }))
      });

    default:
      break;
  }

  return state;
};