import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route } from "react-router-dom"

import Home from './screens/Home';
import WebSocketHandler from './components/WebSocketHandler';
import ListAllSmartDevices from './screens/ListAllSmartDevices';
import SmartDevice from '../../web/src/screens/SmartDevice';
import MqttDebug from '../../web/src/screens/MqttDebug';
import Authentication from './screens/Authentication';
import { actions } from 'darwin_core';

const main = (props) => {

  const { webSocketOpened, webSocketMessageReceived, webSocketClosed } = props;
  // const webSocketOpened = () => console.log("Open!");
  // const webSocketClosed = () => console.log("Closed!");
  // const receivedWebSocketData = (data) => console.log("Received", data);

  return (
    <Authentication>
      <WebSocketHandler
        onOpen={webSocketOpened}
        onMessage={e => webSocketMessageReceived(e)}
        onClose={e => webSocketClosed(e.code, e.reason)}
      >
        {(sendWsMessage) => {
          return (
            <Router>
              <div>
                <Route path="/" exact
                  render={(props) => <Home {...props} sendWsMessage={sendWsMessage}/>} />
                <Route path="/Gateways/" component={ListAllSmartDevices} />
                <Route path="/Gateway/" component={SmartDevice} />
                <Route path="/MQTT/" component={MqttDebug} />
              </div>
            </Router>
          );
        }}
      </WebSocketHandler>

    </Authentication>
)};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, actions)(main);
