import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import { reducers } from 'darwin_core';

// Root Reducer
const rootReducer = combineReducers({
  auth: reducers.auth,
  authSensitive: reducers.authSensitive,
  gatewayManagement: reducers.gatewayManagement,
  prt: reducers.prt,
  pendingPrt: reducers.pendingPrt,
  devices: reducers.devices,
});

const store = createStore(
  rootReducer,
  applyMiddleware(
    thunk,
  ),
);

export default store;
